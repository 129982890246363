import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useAppointmentService } from '../../hooks';
import { AppointmentMessage, AppointmentRating } from '../../types';
import Errors from './errors';
import Messages from './messages';
import Ratings from './ratings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

export type AppointmentInfoProps = {
  appointmentId: string;
};

export default function AppointmentInfo({
  appointmentId,
}: AppointmentInfoProps) {
  const classes = useStyles();
  const service = useAppointmentService();

  /* ~~~ State ~~~ */

  const [expanded, setExpanded] = useState<string | false>('errors');

  const [messages, setMessages] = useState<AppointmentMessage[]>([]);

  const [ratings, setRatings] = useState<AppointmentRating[]>([]);

  /* ~~~ Effects ~~~ */

  /**
   * Used to load the messages and ratings for an appointment
   */
  useEffect(() => {
    async function getAppointmentData() {
      const [messages, ratings] = await Promise.all([
        service.messages.getByAppointment(appointmentId),
        service.ratings.getByAppointment(appointmentId),
      ]);

      setMessages(messages);
      setRatings(ratings);
    }

    getAppointmentData();
  }, [service, appointmentId]);

  /* ~~~ Utils ~~~ */

  function handleChange(panel: string) {
    return (_: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  }

  /* ~~~ Render ~~~ */

  return (
    <div className={classes.root}>
      <Grid item>
        <Box m={3} display="flex" justifyContent="center">
          <Typography variant="h5">
            <Box component="span" mr={1} fontWeight="bold">
              Appt. ID:
            </Box>
          </Typography>
          <Typography variant="h5">{appointmentId}</Typography>
        </Box>
      </Grid>
      <Accordion
        expanded={expanded === 'messages'}
        onChange={handleChange('messages')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="messages-content"
          id="messages-header">
          <Typography className={classes.heading}>Messages</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Messages messages={messages} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'ratings'}
        onChange={handleChange('ratings')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="ratings-content"
          id="ratings-header">
          <Typography className={classes.heading}>Ratings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Ratings ratings={ratings} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'errors'}
        onChange={handleChange('errors')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="errors-content"
          id="errors-header">
          <Typography className={classes.heading}>Errors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Errors appointmentId={appointmentId} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
