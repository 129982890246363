import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import AppointmentSearch from '../../components/appointment-search';

export default function Home() {
  return (
    <Grid container direction="column">
      <Grid item>
        <Box m={3}>
          <Typography variant="h5">Appointment Search Page</Typography>
        </Box>
      </Grid>
      <Grid item>
        <AppointmentSearch />
      </Grid>
    </Grid>
  );
}
