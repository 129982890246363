import { getConfigValue } from '@timwoods/bf-config';
import qs from 'qs';
import { debugLog, errorLog } from '../utils';

export type ApiOptions = {
  token: string;
  baseUrl?: string;
};

export type Api = ReturnType<typeof api>;

export function api({ token, baseUrl }: ApiOptions) {
  return { get, post };

  async function get<R = any, Q = Record<string, any>>(
    path: string,
    query?: Q,
  ): Promise<R | null> {
    const fullURL = `${baseUrl ? baseUrl : getApiUrl()}/${path}`;
    let url = new URL('http://localhost');

    try {
      url = new URL(fullURL);
    } catch (err) {
      errorLog('failed to GET to the following invalid url', fullURL);
      return null;
    }
    if (query) {
      url.search = qs.stringify(query, { encode: false });
    }

    try {
      const headers: Record<string, string> = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      };

      const rawRes = await fetch(url.toString(), {
        method: 'GET',
        mode: 'cors',
        headers,
      });

      if (!rawRes.ok) {
        errorLog(
          `encountered an error GET response from ${path}`,
          rawRes.status,
          rawRes.statusText,
        );
        return null;
      }

      try {
        if (!rawRes.body) {
          debugLog('GET request to', path, 'returned an empty body');
          return null;
        }

        const res = await rawRes.json();
        return res;
      } catch (err) {
        errorLog(
          `encountered an error while decoding a GET response from ${path}`,
        );
        return null;
      }
    } catch (err) {
      errorLog(
        `encountered an unexpected error while sending a GET request to ${path}`,
      );
      return null;
    }
  }

  async function post<R = any, B = Record<string, any>>(
    path: string,
    body?: B,
  ): Promise<R | null> {
    const fullURL = `${getApiUrl()}/${path}`;
    let url = new URL('http://localhost');

    try {
      url = new URL(fullURL);
    } catch (err) {
      errorLog('failed to POST to the following invalid url', fullURL);
      return null;
    }

    const headers: Record<string, string> = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    };

    let payload;
    if (body) {
      try {
        payload = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      } catch (err) {
        errorLog(`failed to encode the body for the POST request to ${path}`);
        return null;
      }
    }

    try {
      const rawRes = await fetch(url.toString(), {
        method: 'POST',
        mode: 'cors',
        body: payload,
        headers,
      });

      if (!rawRes.ok) {
        errorLog(
          `encountered an error POST response from ${path}`,
          rawRes.status,
          rawRes.statusText,
          headers,
        );
        return null;
      }

      try {
        if (!rawRes.body) {
          debugLog('POST request to', path, 'returned an empty body');
          return null;
        }

        const res = await rawRes.json();
        return res;
      } catch (err) {
        errorLog(
          `encountered an error while decoding a POST response from ${path}`,
        );
        return null;
      }
    } catch (err) {
      errorLog(
        `encountered an unexpected error while sending a POST request to ${path}`,
      );
      return null;
    }
  }
}

/* ~~~ Utils ~~~ */

function getApiUrl() {
  const envVal = getConfigValue('REACT_APP_API_URL');
  const stringVal = String(envVal);
  if (stringVal) {
    return stringVal;
  }

  return (
    'https://' +
    window.location.hostname
      .split('.')
      .map((c) => (c === 'fe' ? 'be' : c))
      .join('.')
  );
}
export default api;
