import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Modal,
  TextField,
} from '@material-ui/core';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useCardStyles } from './style';
import { WorkBook } from 'xlsx';
type SpreadsheetConfigProps = {
  sheetName: string;
  setSheetName: (sn: string) => any;
  appointmentIdColumn: string;
  setAppointmentIdColumn: (sn: string) => any;
  dateColumn: string;
  setDateColumn: (sn: string) => any;
  timezoneColumn: string;
  setTimezoneColumn: (sn: string) => any;
  orderIdColumn: string;
  setOrderIdColumn: (sn: string) => any;
  providerIdColumn: string;
  setProviderIdColumn: (sn: string) => any;
  workbook: WorkBook;
  open?: boolean;
  onClose?: () => any;
};
const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
export const SpreadsheetConfig: FunctionComponent<SpreadsheetConfigProps> = ({
  sheetName,
  setSheetName,
  workbook,
  appointmentIdColumn,
  setAppointmentIdColumn,
  dateColumn,
  setDateColumn,
  timezoneColumn,
  setTimezoneColumn,
  orderIdColumn,
  setOrderIdColumn,
  providerIdColumn,
  setProviderIdColumn,
  open,
  onClose,
}) => {
  const c = useCardStyles();
  const columnHeaders = useMemo(() => {
    const cols = [];
    if (workbook && workbook.SheetNames.includes(sheetName)) {
      const sheet = workbook.Sheets[sheetName];
      for (const letter of alphabet) {
        if (
          sheet[`${letter.toUpperCase()}1`] &&
          typeof sheet[`${letter.toUpperCase()}1`]?.w === 'string' &&
          sheet[`${letter.toUpperCase()}1`].w
        ) {
          cols.push(sheet[`${letter.toUpperCase()}1`].w);
        }
      }
    }
    return cols;
  }, [workbook, sheetName]);
  useEffect(() => {
    if (!workbook || workbook.SheetNames.includes(sheetName)) {
      return;
    }
    setSheetName(workbook.SheetNames[0]);
  }, [workbook]);
  useEffect(() => {
    if (columnHeaders.length >= 1 && orderIdColumn === '') {
      setOrderIdColumn(columnHeaders[0]);
    }
    if (columnHeaders.length >= 2 && appointmentIdColumn == '') {
      setAppointmentIdColumn(columnHeaders[1]);
    }
    if (columnHeaders.length >= 3 && providerIdColumn == '') {
      setProviderIdColumn(columnHeaders[2]);
    }
    if (columnHeaders.length >= 4 && dateColumn == '') {
      setDateColumn(columnHeaders[3]);
    }
    if (columnHeaders.length >= 5 && timezoneColumn == '') {
      setTimezoneColumn(columnHeaders[4]);
    }
  }, [
    columnHeaders,
    appointmentIdColumn,
    dateColumn,
    timezoneColumn,
    orderIdColumn,
    providerIdColumn,
  ]);

  return (
    <Modal open={open || false} onClose={onClose}>
      <Card style={{ margin: 1 }}>
        <CardHeader title="Spreadsheet Config" className={c.cardHeader}>
          {' '}
        </CardHeader>
        <CardContent>
          <TextField
            style={{
              width: '100%',
              margin: 8,
            }}
            select
            value={sheetName}
            onChange={(s) => {
              setSheetName(s.target.value);
            }}
            variant="outlined"
            label="Workbook Sheet">
            {workbook?.SheetNames.map((sn, i) => {
              return (
                <MenuItem key={sn} value={sn}>
                  {sn}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            style={{
              width: '100%',
              margin: 8,
            }}
            select
            value={appointmentIdColumn}
            onChange={(s) => {
              setAppointmentIdColumn(s.target.value);
            }}
            variant="outlined"
            label="Appointment ID Column">
            {columnHeaders.map((sn) => {
              return (
                <MenuItem key={sn} value={sn}>
                  {sn}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            style={{
              width: '100%',
              margin: 8,
            }}
            select
            value={dateColumn}
            onChange={(s) => {
              setDateColumn(s.target.value);
            }}
            variant="outlined"
            label="Date Column">
            {columnHeaders.map((sn) => {
              return (
                <MenuItem key={sn} value={sn}>
                  {sn}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            style={{
              width: '100%',
              margin: 8,
            }}
            select
            value={providerIdColumn}
            onChange={(s) => {
              setProviderIdColumn(s.target.value);
            }}
            variant="outlined"
            label="Provider ID Column">
            {columnHeaders.map((sn) => {
              return (
                <MenuItem key={sn} value={sn}>
                  {sn}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            style={{
              width: '100%',
              margin: 8,
            }}
            select
            value={orderIdColumn}
            onChange={(s) => {
              setOrderIdColumn(s.target.value);
            }}
            variant="outlined"
            label="Order ID Column">
            {columnHeaders.map((sn) => {
              return (
                <MenuItem key={sn} value={sn}>
                  {sn}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            style={{
              width: '100%',
              margin: 8,
            }}
            select
            value={timezoneColumn}
            onChange={(s) => {
              setTimezoneColumn(s.target.value);
            }}
            variant="outlined"
            label="Timezone Column">
            {columnHeaders.map((sn) => {
              return (
                <MenuItem key={sn} value={sn}>
                  {sn}
                </MenuItem>
              );
            })}
          </TextField>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => {
              onClose && onClose();
            }}>
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
