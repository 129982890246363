import { useEffect, useState } from 'react';
import { useAuthToken } from '.';
import makeApi from '../services/api';
import { AppointmentMessage, AppointmentRating } from '../types';
import { getAppointmentApiUrl } from '../utils';

export type AppointmentService = {
  messages: {
    getByAppointment: (appointmentId: string) => Promise<AppointmentMessage[]>;
  };
  ratings: {
    getByAppointment: (appointmentId: string) => Promise<AppointmentRating[]>;
  };
};

export default function useAppointmentService(): AppointmentService {
  const [service, setService] = useState<AppointmentService>(defaultService);

  const token = useAuthToken();

  useEffect(() => {
    if (!token) {
      return;
    }

    const baseUrl = getAppointmentApiUrl();
    const api = makeApi({ token: token || '', baseUrl });

    const newService = {
      messages: {
        getByAppointment: async (appointmentId: string) => {
          const messages = await api.get<AppointmentMessage[]>(
            `messages/${appointmentId}`,
          );
          return Array.isArray(messages) ? messages : [];
        },
      },
      ratings: {
        getByAppointment: async (appointmentId: string) => {
          const ratings = await api.get<AppointmentRating[]>(
            `ratings/${appointmentId}`,
          );
          return Array.isArray(ratings) ? ratings : [];
        },
      },
    };

    setService(newService);
  }, [token]);

  return service;
}

function defaultService(): AppointmentService {
  return {
    messages: {
      getByAppointment: async () => [],
    },
    ratings: {
      getByAppointment: async () => [],
    },
  };
}
