import React, { useState, useEffect } from 'react';
import { useAuth } from '../../auth';
import { emptySearchResponse } from '../../utils';
import { SearchFn, Connection, SearchOptions } from '../../types';
import api from '../../services/api';
import { searchConnections } from '../../services';
import { Button, Grid } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { exportToCsv } from './export2csv';
import { getConfigValue } from '@timwoods/bf-config';

function buildDates() {
  const dates: Array<{ month: number; year: number }> = [];
  let i = 0;
  let month = new Date().getMonth() + 1;
  let year = new Date().getFullYear();
  // const monthstr = month < 10 ? `0${month}` : month;
  while (i < 12) {
    // Telehealth started in 4/2020 so no need to build report dates prior to it
    if ((month >= 4 && year > 2019) || year > 2020) {
      dates.push({ year, month });
    }
    month = month - 1;
    if (month < 1) {
      month = 12;
      year = year - 1;
    }
    i++;
  }
  return dates;
}

function range(start: number, end: number): number[] {
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
}

function cleanResult(header: string[], results: Connection[]): string[][] {
  return results.map((r: any) => {
    return header.map((key) => {
      return r[key] || '';
    });
  });
}

export default function ReportsSection() {
  const auth = useAuth();
  const [search, setSearch] = useState<SearchFn<Connection>>(
    () => async () => emptySearchResponse<Connection>(),
  );
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    auth.getTokenSilently()?.then((token) => {
      if (token) {
        setSearch(() => searchConnections(api({ token })));
      }
    });
  }, [auth]);
  const dates = buildDates();

  const downloadDates = async ({
    month,
    year,
  }: {
    month: number;
    year: number;
  }) => {
    setLoading(true);
    const size = 10000;
    const monthstr = month < 10 ? `0${month}` : month;
    const searchOptions: SearchOptions = {
      pagination: { page: 1, size },
      filter: {
        created_at: `begin:${year}-${monthstr}`,
      },
    };
    const REACT_APP_ERROR_REGEXP_APPOINTMENT_ID = getConfigValue(
      'REACT_APP_ERROR_REGEXP_APPOINTMENT_ID',
    );
    if (REACT_APP_ERROR_REGEXP_APPOINTMENT_ID && searchOptions.filter) {
      searchOptions.filter.appointment_id = `regex:${REACT_APP_ERROR_REGEXP_APPOINTMENT_ID}`;
    }
    // const res = await search(searchOptions);
    const header = [
      'appointment_id',
      'browser',
      'created',
      'has_errors',
      'ip',
      'os',
      'user',
    ];
    let data = [header];

    const downloadPage = async (pageNum: number) => {
      const s = searchOptions;
      s.pagination.page = pageNum;
      return await search(s);
    };
    let done = false;
    let page = 1;
    while (!done) {
      const res = await downloadPage(page);
      if (res?.results?.length) {
        data.push(...cleanResult(header, res.results));
      } else {
        done = true;
      }
      page++;
    }
    /* if( res.total > res.count ){
            const otherResults = await Promise.all(
                range( 2 , Math.ceil(res.total / size ) ).map(
                    ( page ) => {
                        const s = searchOptions;
                        s.pagination.page = page;
                        return search(s);
                    }
                )
            );
            for ( const res of otherResults ){
                data.push( ...cleanResult( header , res.results))
            }
        } */
    exportToCsv(`report-${year}-${month}.csv`, data);
    setLoading(false);
  };
  return (
    <div>
      Reports
      {loading && <div> Your Report is building, please wait</div>}
      <Grid container>
        {dates.map((date) => {
          return (
            <Grid xs={12} item key={`${date.year}-${date.month}`}>
              <Button
                variant="outlined"
                onClick={() => {
                  downloadDates(date);
                }}>
                <AssignmentIcon></AssignmentIcon> {date.year}/
                {date.month < 10 ? '0' : ''}
                {date.month}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
