import React from 'react';
import { useAuth } from '../../auth';
import './profile-picture.scss';
import Avatar from '@material-ui/core/Avatar';

export type ProfilePictureProps = {
  className?: string;
};

export default function ProfilePicture({ className }: ProfilePictureProps) {
  const { loading, user } = useAuth();

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return <Avatar alt={user.name} src={user.picture} />;
}
