import { DropzoneArea } from 'material-ui-dropzone';
import { FunctionComponent, useEffect, useState } from 'react';
import { read as readXls, WorkBook } from 'xlsx';

type FileUploadProps = {
  setWorkbook: (wb: false | WorkBook) => any;
};

export const FileUpload: FunctionComponent<FileUploadProps> = ({
  setWorkbook,
}) => {
  const [file, setFile] = useState<false | File>(false);
  const [fileContents, setFileContents] = useState<false | ArrayBuffer>(false);

  useEffect(() => {
    if (fileContents) {
      setWorkbook(readXls(fileContents));
      return;
    }
    setWorkbook(false);
  }, [fileContents]);

  useEffect(() => {
    let loaded = true;
    if (!file) {
      // setAppointments(false);
      return;
    }
    file.arrayBuffer().then((data) => {
      if (loaded) {
        setFileContents(data);
      }
    });

    return () => {
      loaded = false;
    };
  }, [file]);
  return (
    <DropzoneArea
      onChange={(files) => {
        setFile(files[0] || false);
      }}
    />
  );
};
