import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export type Presets = 'FUTURE_APPT' | 'LATE_LOGIN' | 'CUSTOM';

type PresetProps = {
  onChange: (preset: Presets) => void;
  preset: Presets;
};

export function Preset({ onChange, preset }: PresetProps) {
  return (
    <ToggleButtonGroup
      exclusive
      value={preset}
      onChange={(e, value) => {
        onChange(value as Presets);
      }}>
      <ToggleButton value="FUTURE_APPT">Future Appt</ToggleButton>
      <ToggleButton value="LATE_LOGIN">Late Logins</ToggleButton>
      <ToggleButton value="CUSTOM">Custom</ToggleButton>
    </ToggleButtonGroup>
  );
}
