import {
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { green, orange, red } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import { DateTime } from 'luxon';
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthToken, useDebouncedState } from '../../../hooks';
import {
  DateDifference,
  IAppointment,
  searchAppointments,
} from '../../../services';
import api from '../../../services/api';
import { SearchOptions, SortDirection } from '../../../types';
import { eqFilter, geFilter, leFilter, likeFilter } from '../../../utils';
import { DateRange, DateRangeValue } from '../../calendar/DateRange';
import { Preset, Presets } from '../../calendar/Presets';
import Table, { Column, RowData } from '../../table';
import { ScheduleReport } from './ScheduleReport';

const ontimeFormat = (prefix: 'patient' | 'provider', cst?: boolean) => {
  return (str: any, rowData: any) => {
    const row: IAppointment = rowData;
    const dateDifference: DateDifference =
      typeof row[`${prefix}_time_difference_before` as keyof IAppointment] !==
      'number'
        ? row[`${prefix}_time_difference_after` as keyof IAppointment]
        : (row[
            `${prefix}_time_difference_before` as keyof IAppointment
          ] as any);
    if (dateDifference === null) {
      return (
        <Box
          style={{
            backgroundColor: red[400],
            color: 'white',
            borderRadius: 10,
            textAlign: 'center',
          }}>
          <Typography variant="body2">No Login</Typography>
        </Box>
      );
    }
    const scheduledDate = DateTime.fromISO(row.scheduled_time).setZone(
      row.local_timezone || 'America/Chicago',
    );
    const startDate = DateTime.fromISO(row.scheduled_time)
      .plus({
        seconds: dateDifference,
      })
      .setZone(
        cst ? 'America/Chicago' : row.local_timezone || 'America/Chicago',
      );
    let FORMAT = DateTime.TIME_SIMPLE;
    const needsSecond =
      scheduledDate.toFormat('yyyy-MM-dd') !== startDate.toFormat('yyyy-MM-dd');

    // If the difference is  over 2 hours
    if (Math.abs(dateDifference) > 60 * 60 * 2) {
      return (
        <Box
          style={{
            backgroundColor: red[400],
            color: 'white',
            borderRadius: 10,
            textAlign: 'center',
          }}>
          <Typography variant="body2">
            {startDate.toLocaleString(FORMAT)}
          </Typography>
          {needsSecond && (
            <Typography variant="subtitle2">
              {startDate.toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          )}
        </Box>
      );
    }
    if (dateDifference > 0) {
      return (
        <Box
          style={{
            backgroundColor: orange[400],
            borderRadius: 10,
            textAlign: 'center',
          }}>
          <Typography variant="body2">
            {startDate.toLocaleString(FORMAT)}
          </Typography>
          {needsSecond && (
            <Typography variant="subtitle2">
              {startDate.toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          )}
        </Box>
      );
    }
    return (
      <Box
        style={{
          backgroundColor: green[400],
          borderRadius: 10,
          textAlign: 'center',
        }}>
        <Typography variant="body2">
          {startDate.toLocaleString(FORMAT)}
        </Typography>
        {needsSecond && (
          <Typography variant="subtitle2">
            {startDate.toLocaleString(DateTime.DATE_SHORT)}
          </Typography>
        )}
      </Box>
    );
  };
};
const columns: Column[] = [
  { name: 'appointment_id', label: 'Appt. ID', type: 'string' },

  { name: 'local_timezone', label: 'Timezone', type: 'string' },
  {
    name: 'scheduled_time',
    label: 'Scheduled Date',
    type: 'string',
    formatValue: (str, row: any) => {
      const scheduledDate = DateTime.fromISO(row.scheduled_time).setZone(
        row.local_timezone || 'America/Chicago',
      );
      return scheduledDate.toLocaleString(DateTime.DATE_SHORT);
    },
  },
  {
    name: 'scheduled_time',
    label: 'Time',
    type: 'string',
    formatValue: (str, row: any) => {
      const scheduledDate = DateTime.fromISO(row.scheduled_time).setZone(
        row.local_timezone || 'America/Chicago',
      );
      return scheduledDate.toLocaleString(DateTime.TIME_SIMPLE);
    },
  },
  {
    name: 'patient_closest_seconds_away',
    label: 'Patient ( Local )',
    type: 'string',
    formatValue: ontimeFormat('patient'),
  },
  {
    name: 'provider_closest_seconds_away',
    label: 'Examiner ( Local )',
    type: 'string',
    formatValue: ontimeFormat('provider'),
  },
  {
    name: 'patient_time_difference_before',
    label: 'Hidden',
    hidden: true,
    type: 'number',
  },
  {
    name: 'patient_time_difference_after',
    label: 'Hidden',
    hidden: true,
    type: 'number',
  },
  {
    name: 'provider_time_difference_before',
    label: 'Hidden',
    hidden: true,
    type: 'number',
  },
  {
    name: 'provider_time_difference_after',
    label: 'Hidden',
    hidden: true,
    type: 'number',
  },
];

type Filter = 'NONE' | 'LATE' | 'NOSHOW';
/* const filterOptions: Filter[] = [
  'NONE',
  'LATE',
  'NOSHOW_LATE',
  'NOSHOW_ONTIME',
]; */

const FILTER_NAME: Record<Filter, string> = {
  NONE: 'None',
  LATE: 'Late',
  NOSHOW: 'No Login',
};

const FILTERS: Record<Filter, Record<string, string>> = {
  NONE: {},
  LATE: {
    time_difference_before: 'is:NULL',
  },
  NOSHOW: {
    closest_seconds_away: 'is:NULL',
  },
};

export const ScheduledAppointmentSearch: FunctionComponent = () => {
  const history = useHistory();
  const token = useAuthToken();

  /* ~~~ State ~~~ */

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [appointmentId, setAppointmentId] = useDebouncedState('', 1000);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [sortField, setSortField] = useState<Column>(columns[0]);
  const [onlyErrors, setOnlyErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRangeValue] = useState<DateRangeValue | null>(null);
  const [preset, setPreset] = useState<Presets | null>(null);

  const [patentFilter, setPatientFilter] = useState<Filter>('NONE');
  const [providerFilter, setProviderFilter] = useState<Filter>('NONE');

  const [rows, setRows] = useState<RowData[][]>([]);

  const [searchOptions, setSearchOptions] = useDebouncedState<SearchOptions>(
    setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: sortDirection },
      },
      appointmentId,
      dateRange,
      patentFilter,
      providerFilter,
    ),
    300,
  );

  /* ~~~ Effects ~~~ */

  /**
   * Update search options when any significant values change
   */
  useEffect(() => {
    const options: SearchOptions = setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: sortDirection },
      },
      appointmentId,
      dateRange,
      patentFilter,
      providerFilter,
    );

    if (onlyErrors) {
      options.filter = {
        ...options.filter,
        patient_has_errors: eqFilter('true'),
        provider_has_errors: eqFilter('true'),
      };
    }

    setSearchOptions(options);
  }, [
    setSearchOptions,
    appointmentId,
    currentPage,
    rowsPerPage,
    onlyErrors,
    sortDirection,
    sortField,
    patentFilter,
    providerFilter,
    dateRange,
  ]);

  /**
   * Used to make sure that we are never on a page that can't exist
   */
  useEffect(() => {
    if (rowsPerPage > total && currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [currentPage, rowsPerPage, total]);

  /**
   * Used to update search results whenever the search options change
   */
  useEffect(() => {
    let loaded = true;

    async function getResults() {
      if (
        !token ||
        loading ||
        !loaded ||
        !searchOptions?.filter?.scheduled_time
      ) {
        return;
      }

      setLoading(true);

      const search = searchAppointments(api({ token }));
      const { count, total, results } = await search(searchOptions);

      setRows(resultsToRows(results));

      if (count > total) {
        setTotal(0);
      } else {
        setTotal(total);
      }

      setTimeout(() => setLoading(false), 300);
    }

    getResults();

    return () => {
      loaded = false;
    };
  }, [token, searchOptions]);

  useEffect(() => {
    if (preset) {
      if (preset === 'LATE_LOGIN') {
        setPatientFilter('LATE');
        setProviderFilter('LATE');
      } else if (preset === 'FUTURE_APPT') {
        setPatientFilter('NONE');
        setProviderFilter('NONE');
      }
    }
  }, [preset]);

  useEffect(() => {
    setPreset('CUSTOM');
  }, []);

  /* ~~~ Utils ~~~ */

  function handleAppointmentIdChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const appointmentId = String(event.target.value);
    setAppointmentId(appointmentId);
  }

  function handleChangeCurrentPage(currentPage: number) {
    if (!loading) {
      setCurrentPage(currentPage);
    }
  }

  function handleChangeRowsPerPage(rowsPerPage: number) {
    if (!loading) {
      setRowsPerPage(rowsPerPage);
    }
  }

  function handleChangeSortDirection(sortDirection: SortDirection) {
    if (!loading) {
      setSortDirection(sortDirection);
    }
  }

  function handleChangeSortField(sortField: Column) {
    if (!loading) {
      setSortField(sortField);
      setSortDirection('desc');
    }
  }

  function handleRowClick(row: RowData[]) {
    const id = row[1];
    history.push(`/info/${id}`);
  }

  const handleDateRangeChange = useCallback((dateRangeValue) => {
    setDateRangeValue(dateRangeValue);
  }, []);

  /* ~~~ Render ~~~ */

  return (
    <>
      <Grid container>
        <Grid item md={preset !== 'CUSTOM' ? 1 : 4}>
          <Box m={1}>
            {preset && (
              <DateRange onChange={handleDateRangeChange} preset={preset} />
            )}
          </Box>
        </Grid>
        <Grid item md={preset !== 'CUSTOM' ? 6 : 2}>
          <Box m={1}>
            <TextField
              style={{ width: '100%' }}
              autoFocus
              label="Appointment ID"
              variant="filled"
              onChange={handleAppointmentIdChange}
            />
          </Box>
        </Grid>
        {preset === 'CUSTOM' && (
          <>
            <Grid item md={2}>
              <Box m={1}>
                <TextField
                  select
                  value={patentFilter}
                  style={{ width: '100%' }}
                  variant="filled"
                  onChange={(e) => {
                    setPatientFilter(e.target.value as Filter);
                  }}
                  label="Patient Filter">
                  {(Object.keys(FILTERS) as Filter[]).map((filter: Filter) => {
                    return (
                      <MenuItem key={filter} value={filter}>
                        {FILTER_NAME[filter]}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box m={1}>
                <TextField
                  select
                  value={providerFilter}
                  style={{ width: '100%' }}
                  variant="filled"
                  onChange={(e) => {
                    setProviderFilter(e.target.value as Filter);
                  }}
                  label="Examiner Filter">
                  {(Object.keys(FILTERS) as Filter[]).map((filter: Filter) => {
                    return (
                      <MenuItem key={filter} value={filter}>
                        {FILTER_NAME[filter]}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Grid>
          </>
        )}
        <Grid item md={preset !== 'CUSTOM' ? 5 : 2}>
          <Box m={1}>
            <InputLabel>Show Only Errors</InputLabel>
            <Checkbox
              onChange={(event, val) => {
                setOnlyErrors(val);
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          margin: 10,
          padding: 12,
        }}>
        <Grid item xs={9} style={{ textAlign: 'left', marginLeft: -22 }}>
          {preset && (
            <Preset
              onChange={(preset) => {
                setPreset(preset);
              }}
              preset={preset}
            />
          )}
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right', marginRight: -42 }}>
          <ScheduleReport searchOptions={searchOptions} />
        </Grid>
      </Grid>
      <Table
        loading={loading}
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        onClick={handleRowClick}
        onChangeCurrentPage={handleChangeCurrentPage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        sortDirection={sortDirection}
        onChangeSortDirection={handleChangeSortDirection}
        sortField={sortField}
        onChangeSortField={handleChangeSortField}
      />
    </>
  );
};

function resultsToRows(results: IAppointment[]) {
  return results.map((data: any) => {
    const row = [];
    for (const column of columns) {
      const value = data[column.name];
      if (valueIsDate(column, value)) {
        row.push(new Date(value).toLocaleString());
      } else {
        row.push(value);
      }
    }
    return row as RowData[];
  });
}

function valueIsDate(column: Column, value: any): value is string {
  return column.name === 'created';
}

function setAppointmentFilter(
  options: SearchOptions,
  appointmentId: string,
  dateRangeValue: DateRangeValue | null,
  parentFilter: Filter,
  providerFilter: Filter,
) {
  if (!options.filter) {
    options.filter = {};
  }

  if (appointmentId) {
    options.filter.appointment_id = likeFilter(appointmentId);
  }

  if (dateRangeValue) {
    options.filter.scheduled_time = [
      geFilter(dateRangeValue.start.toISO()),
      leFilter(dateRangeValue.end.toISO()),
    ] as any;
  }

  if (parentFilter !== 'NONE') {
    for (const filter of Object.keys(FILTERS[parentFilter])) {
      options.filter[`patient_${filter}`] = FILTERS[parentFilter][filter];
    }
  }

  if (providerFilter !== 'NONE') {
    for (const filter of Object.keys(FILTERS[providerFilter])) {
      options.filter[`provider_${filter}`] = FILTERS[providerFilter][filter];
    }
  }

  return options;
}
