import { getConfigValue } from "@timwoods/bf-config";

export function debugEnabled() {
  const envVal = getConfigValue("REACT_APP_DEBUG" , "false");
  if (!envVal || envVal === "false" || envVal === '') {
    return false;
  }
  const numberVal = Number(envVal);
  return !Number.isNaN(numberVal) && numberVal > 0;
}

export function debugLog(...args: any[]) {
  if (debugEnabled()) {
    console.log(...args);
  }
}
