import React from 'react';
import { useParams } from 'react-router-dom';
import AppointmentInfo from '../../components/appointment-info';

type InfoRouteParams = {
  id: string;
};

export default function AppointmentInfoRoute() {
  const { id } = useParams<InfoRouteParams>();
  return <AppointmentInfo appointmentId={id} />;
}
