import { Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { DateTime } from 'luxon';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAuthToken } from '../../../hooks';
import { searchAppointments } from '../../../services';
import api from '../../../services/api';
import { SearchOptions } from '../../../types';
import { exportToCsv } from '../../reports/export2csv';

type ScheduleReportProps = {
  searchOptions: SearchOptions;
};

const timeOffset = (scheduledStart: string, seconds: null | number) => {
  if (typeof seconds !== 'number') {
    return 'No Login';
  }
  return DateTime.fromISO(scheduledStart)
    .plus({ seconds })
    .toLocaleString(DateTime.DATETIME_FULL);
};

export const ScheduleReport: FunctionComponent<ScheduleReportProps> = ({
  searchOptions,
}) => {
  const [downloading, setDownloading] = useState(false);
  const token = useAuthToken();

  useEffect(() => {
    if (!downloading || !token) {
      return;
    }
    let loaded = true;
    const search = searchAppointments(api({ token }));
    search({
      ...searchOptions,
      pagination: {
        size: 5000,
        page: 1,
      },
    }).then((data) => {
      const { results } = data;
      const header = [
        'Appointment ID',
        'Order ID',
        'Provider ID',
        'Scheduled Time',
        'Examiner Time',
        'Patient Time',
      ];
      const cleanData = results.map((r) => {
        const scheduledTime = DateTime.fromISO(r.scheduled_time);

        return [
          r.appointment_id,
          r.order_id,
          r.provider_id,
          scheduledTime.toLocaleString(DateTime.DATETIME_FULL),
          timeOffset(
            r.scheduled_time,
            r.provider_time_difference_before ||
              r.provider_time_difference_after,
          ),
          timeOffset(
            r.scheduled_time,
            r.patient_time_difference_before || r.patient_time_difference_after,
          ),
        ];
      });
      exportToCsv(`csvreport`, [header, ...cleanData]);
      setDownloading(false);
    });
    return () => {
      loaded = false;
    };
  }, [downloading, token]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={downloading}
        style={{
          background: green[400],
          color: 'white',
        }}
        onClick={() => {
          setDownloading(true);
        }}>
        Download All
      </Button>
    </>
  );
};
