import { Grid, Typography, Box } from "@material-ui/core";
import React from 'react';
import ReportsSection from '../../components/reports/ReportsSection';
export default function Reports() {
    return (
      <Grid container direction="column">
        <Grid item>
          <Box m={3}>
            <Typography variant="h5">Reports Page</Typography>
          </Box>
        </Grid>
        <Grid item>
          <ReportsSection />
        </Grid>
      </Grid>
    );
  }