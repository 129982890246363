import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Works like useState, but the returned value will be debounced
 *
 * @param initialValue The initial value for the state
 * @param delay The number of ms to debounce state changes
 */
export default function useDebouncedState<T extends any = any>(
  initialValue: T,
  delay: number,
): [T, Dispatch<SetStateAction<T>>] {
  const [internalValue, setInternalValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(internalValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, internalValue]);

  return [debouncedValue, setInternalValue];
}
