import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import Table, { Column, RowData } from '../../components/table';
import { useAuthToken } from '../../hooks';
import api from '../../services/api';

const columns: Column[] = [
  { name: 'appointment_code', label: 'Appt. ID', type: 'string' },
  { name: 'created_at', label: 'Date', type: 'string' },
  { name: 'os', label: 'OS', type: 'string' },
  { name: 'browser', label: 'Browser', type: 'string' },
  { name: 'error_text', label: 'Error', type: 'string' },
  { name: 'ip', label: 'Ip', type: 'string' },
  {
    name: 'user_id',
    label: 'Type',
    type: 'string',
    formatValue: (input) => {
      return input === '' ? 'Patient' : 'Provider';
    },
  },
];

function resultsToRows(results: any[]): RowData[][] {
  return results.map((data: any) => {
    const row = [];
    for (const column of columns) {
      const value = data[column.name];
      if (column.name === 'created_at') {
        row.push(new Date(value).toLocaleString());
      } else {
        row.push(value);
      }
    }
    return row as any[];
  });
}

type ErrorsProps = {
  appointmentId: string;
};

export default function Errors({ appointmentId }: ErrorsProps) {
  const token = useAuthToken();

  /* ~~~ State ~~~ */

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [errorRows, setErrorRows] = useState<RowData[][]>([]);

  /* ~~~ Effects ~~~ */

  /**
   * Used to update error search results
   */
  useEffect(() => {
    async function getErrorResults() {
      if (!token || loading) {
        return;
      }

      setLoading(true);

      const page = {
        size: rowsPerPage,
        number: currentPage + 1,
      };

      const errors = await api({ token }).get(
        `connection/${appointmentId}/errors`,
        {
          page,
        },
      );

      setErrorRows(resultsToRows(errors.results));
      setTotal(errors.total);

      setTimeout(() => setLoading(false), 300);
    }

    getErrorResults();
  }, [token, rowsPerPage, currentPage]);

  /* ~~~ Utils ~~~ */

  function handleChangeCurrentPage(currentPage: number) {
    if (!loading) {
      setCurrentPage(currentPage);
    }
  }

  function handleChangeRowsPerPage(rowsPerPage: number) {
    if (!loading) {
      setRowsPerPage(rowsPerPage);
    }
  }

  /* ~~~ Render ~~~ */

  return (
    <Grid container direction="column" style={{ top: 50 }}>
      <Grid item>
        <Table
          loading={loading}
          columns={columns}
          rows={errorRows}
          total={total}
          currentPage={currentPage}
          onChangeCurrentPage={handleChangeCurrentPage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          sortDirection="desc"
          onChangeSortDirection={() => {}}
          sortField={{ name: 'created_at', label: 'Created', type: 'string' }}
          onChangeSortField={() => {}}
        />
      </Grid>
    </Grid>
  );
}
