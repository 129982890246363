import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      backgroundColor: '#2196f3',
      color: 'white',
    },
  }),
);
