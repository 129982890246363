import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  withStyles,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { CloudUpload } from '@material-ui/icons';
import { getConfigValue } from '@timwoods/bf-config';
import { FunctionComponent, useMemo } from 'react';
import { WorkBook, utils } from 'xlsx';
import { AdminModals } from './AppointmentAdmin';
import { useCardStyles } from './style';

type SummaryProps = {
  appointments: string[][] | false;
  mapping: Record<string, string>;
  workbook: WorkBook | false;
  sheetName: string;
  timezoneColumn: string;
  modal: AdminModals;
  setModal: (m: AdminModals) => any;
  showAppointments: boolean;
  setShowAppointments: (b: boolean) => any;
  onSubmit: () => any;
};

const GreenCheckbox = withStyles({
  root: {
    color: red[600],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

export const AppointmentUploadSummary: FunctionComponent<SummaryProps> = ({
  appointments,
  mapping,
  workbook,
  sheetName,
  timezoneColumn,
  setModal,
  modal,
  showAppointments,
  setShowAppointments,
  onSubmit,
}) => {
  const c = useCardStyles();
  const REACT_APP_ERROR_REGEXP_APPOINTMENT_ID = getConfigValue(
    'REACT_APP_ERROR_REGEXP_APPOINTMENT_ID',
  );
  const appointmentsValidPercent = useMemo(() => {
    if (appointments === false || appointments.length === 0) {
      return 0;
    }
    const check = new RegExp(REACT_APP_ERROR_REGEXP_APPOINTMENT_ID);
    const valid = appointments.map((v) => check.test(v[0])).filter((v) => v);
    return valid.length / appointments.length;
  }, [REACT_APP_ERROR_REGEXP_APPOINTMENT_ID, appointments]);

  const datesValidPercent = useMemo(() => {
    if (appointments === false || appointments.length === 0) {
      return 0;
    }
    const valid = appointments
      .map((v) => {
        const strDate = parseInt(v[1].split('-')[0]);
        const d = new Date(v[1]);
        // Check its not an invalid date that defaults to now
        if (strDate !== d.getFullYear()) {
          return false;
        }
        if (strDate < 2020) {
          return false;
        }
        // They don't upload more then a year in advance
        if (strDate > new Date().getFullYear() + 1) {
          return false;
        }
        return true;
      })
      .filter((v) => v);
    return valid.length / appointments.length;
  }, [appointments]);

  const timezones = useMemo(() => {
    const current = Object.keys(mapping);
    if (!workbook || !sheetName || !timezoneColumn) {
      return current;
    }
    const sheetData = utils.sheet_to_json(workbook.Sheets[sheetName]);
    const sheetTzs = sheetData
      .map((t: any) => t?.[timezoneColumn])
      .filter((t) => typeof t === 'string')
      .map((t) => t.toLowerCase())
      .reduce((pv, cv) => {
        if (pv.includes(cv) || current.includes(cv)) {
          return pv;
        }
        return [cv, ...pv];
      }, []);
    return [...current, ...sheetTzs];
  }, [workbook, sheetName, timezoneColumn]);

  const timezoneValid = useMemo(() => {
    return Object.values(mapping).filter((v) => !!v).length / timezones.length;
  }, [timezones, mapping]);

  return (
    <Card style={{ margin: 1 }}>
      <CardHeader title="Summary" className={c.cardHeader}></CardHeader>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box style={{ width: '100%', alignContent: 'left' }}>
              <Box style={{ display: 'flex', alignContent: 'flex-start' }}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      indeterminate={
                        !(Math.round(appointmentsValidPercent * 100) === 100)
                      }
                      checked={
                        Math.round(appointmentsValidPercent * 100) === 100
                      }
                    />
                  }
                  label="Appointment ID"
                />
              </Box>
              <Box style={{ display: 'flex', alignContent: 'flex-start' }}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      indeterminate={!(Math.round(timezoneValid * 100) === 100)}
                      checked={Math.round(timezoneValid * 100) === 100}
                    />
                  }
                  label="Valid Timezones"
                />
              </Box>
              <Box style={{ display: 'flex', alignContent: 'flex-start' }}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      indeterminate={
                        !(Math.round(datesValidPercent * 100) === 100)
                      }
                      checked={Math.round(datesValidPercent * 100) === 100}
                    />
                  }
                  label="Valid Dates"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Box style={{ display: 'flex', alignContent: 'flex-start' }}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      indeterminate={!(appointments && appointments.length > 0)}
                      checked={appointments && appointments.length > 0}
                    />
                  }
                  label={`${
                    appointments ? appointments.length : 'No'
                  } Appointments`}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          onClick={() => {
            setModal('timezone');
          }}>
          Edit Timezones
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setModal('spreadsheet');
          }}>
          Edit Spreadsheet Config
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setShowAppointments(!showAppointments);
          }}>
          {showAppointments ? 'Hide Data' : 'Preview Data'}
        </Button>
        <Button
          style={{ color: 'white', backgroundColor: green[600] }}
          color="primary"
          variant="contained"
          startIcon={<CloudUpload />}
          onClick={() => {
            onSubmit();
          }}>
          Upload Appointments
        </Button>
      </CardActions>
    </Card>
  );
};
