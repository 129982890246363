import createAuth0Client, {
  Auth0ClientOptions,
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import React, { useContext, useEffect, useState } from 'react';
import { User } from '../types';
import { debugLog, errorLog } from '../utils';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export type AuthContext = {
  isAuthenticated: boolean;
  loading: boolean;
  popupOpen: boolean;
  user: User;
  getIdTokenClaims: (
    options?: getIdTokenClaimsOptions,
  ) => Promise<IdToken> | undefined;
  getTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string> | undefined;
  loginWithRedirect: (
    options?: RedirectLoginOptions,
  ) => Promise<void> | undefined;
  logout: (options?: LogoutOptions) => void;
  handleRedirectCallback?: () => void;
};

export const AuthContext = React.createContext<AuthContext>({} as any);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = () => {};

export type AuthProps = {
  children?: React.ReactNode;
  onRedirectCallback?: (appState?: any) => void;
} & Auth0ClientOptions;

export default function Auth({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: AuthProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [auth0Client, setAuth0] = useState<Auth0Client | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function initAuth0() {
      try {
        const auth0FromHook = await createAuth0Client(initOptions);
        setAuth0(auth0FromHook);

        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(isAuthenticated);
        if (isAuthenticated) {
          const user = await auth0FromHook.getUser<User>();
          setUser(user);
        }
      } catch (err) {
        errorLog('authenticated failed');
        debugLog(err);
        setIsAuthenticated(false);
        setUser(undefined);
        setAuth0(undefined);
      }

      setLoading(false);
    }

    initAuth0();
  }, []);

  async function handleRedirectCallback() {
    if (!auth0Client) {
      return;
    }

    setLoading(true);

    try {
      await auth0Client.handleRedirectCallback();
      const user = await auth0Client.getUser<User>();
      setUser(user);
    } catch (err) {
      errorLog('failed to handle authentication redirect');
      debugLog(err);
      setUser(undefined);
    }

    setLoading(false);
    setIsAuthenticated(true);
  }

  return (
    <AuthContext.Provider
      value={{
        popupOpen: false,
        isAuthenticated,
        handleRedirectCallback,
        loading,
        user: user as User,
        getIdTokenClaims: (options?: getIdTokenClaimsOptions) =>
          auth0Client ? auth0Client.getIdTokenClaims(options) : undefined,
        loginWithRedirect: (options?: RedirectLoginOptions) =>
          auth0Client ? auth0Client.loginWithRedirect(options) : undefined,
        getTokenSilently: (options?: GetTokenSilentlyOptions) =>
          auth0Client
            ? user
              ? auth0Client.getTokenSilently(options)
              : Promise.resolve('')
            : undefined,
        logout: (options?: LogoutOptions) =>
          auth0Client ? auth0Client.logout(options) : undefined,
      }}>
      {children}
    </AuthContext.Provider>
  );
}
