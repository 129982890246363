import { Connection, SearchOptions, SearchResponse } from '../types';
import { debugLog, emptySearchResponse, sortValue } from '../utils';
import { Api } from './api';
export interface IAppointmentCore {
  appointment_id: string;
  scheduled_time: string;
  local_timezone: string;
  order_id: string;
  provider_id: string;
}

export type DateDifference = number | null;
export interface IAppointment extends IAppointmentCore {
  patient_closest_seconds_away: DateDifference;
  patient_time_difference_after: DateDifference;
  patient_time_difference_before: DateDifference;
  provider_closest_seconds_away: DateDifference;
  provider_time_difference_after: DateDifference;
  provider_time_difference_before: DateDifference;
}
/**
 * Retrieve a list of zero or more connections
 */
export const searchConnections = searchFor<Connection>('connections');
export const searchAppointments = searchFor<IAppointment>('appointments');

function searchFor<T>(resource: string) {
  return (api: Api) =>
    (options: SearchOptions): Promise<SearchResponse<T>> => {
      debugLog(options);
      return api
        .get<SearchResponse<T>>(resource, {
          page: {
            number: options.pagination.page,
            size: options.pagination.size,
          },
          filter: options.filter,
          sort: options.sort
            ? sortValue(options.sort.field, options.sort.direction)
            : undefined,
        })
        .then((res) => res ?? emptySearchResponse<T>());
    };
}
