import { Checkbox, Grid, InputLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthToken, useDebouncedState } from '../../hooks';
import { searchConnections } from '../../services';
import api from '../../services/api';
import { Connection, SearchOptions, SortDirection } from '../../types';
import { eqFilter, geFilter, likeFilter } from '../../utils';
import Table, { Column, RowData } from '../table';

const columns: Column[] = [
  { name: 'appointment_id', label: 'Appt. ID', type: 'string' },
  { name: 'created', label: 'Date', type: 'string' },
  { name: 'os', label: 'OS', type: 'string' },
  { name: 'browser', label: 'Browser', type: 'string' },
  {
    name: 'has_errors',
    label: 'Errors',
    type: 'boolean',
    validate: (input) => !Boolean(input),
  },
  { name: 'ip', label: 'Ip', type: 'string' },
  {
    name: 'user',
    label: 'Type',
    type: 'string',
    formatValue: (input) => {
      return input === '' ? 'Patient' : 'Provider';
    },
  },
];

type ValidPatientTypes = 'NONE' | 'LATE' | 'NO_SHOW';
const PATIENT_FILTER = {};

export default function AppointmentSearch() {
  const history = useHistory();
  const token = useAuthToken();

  /* ~~~ State ~~~ */

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [createdAt, setCreatedAt] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
  );
  const [appointmentId, setAppointmentId] = useDebouncedState('', 1000);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [sortField, setSortField] = useState<Column>(columns[1]);
  const [onlyErrors, setOnlyErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState<RowData[][]>([]);

  const [searchOptions, setSearchOptions] = useDebouncedState<SearchOptions>(
    setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: sortDirection },
      },
      appointmentId,
      createdAt,
    ),
    300,
  );

  /* ~~~ Effects ~~~ */

  /**
   * Update search options when any significant values change
   */
  useEffect(() => {
    const options: SearchOptions = setAppointmentFilter(
      {
        pagination: { page: currentPage + 1, size: rowsPerPage },
        sort: { field: sortField.name, direction: sortDirection },
      },
      appointmentId,
      createdAt,
    );
    if (onlyErrors) {
      options.filter = {
        ...options.filter,
        has_errors: eqFilter('true'),
        valid: eqFilter('false'),
      };
    }

    setSearchOptions(options);
  }, [
    setSearchOptions,
    appointmentId,
    currentPage,
    rowsPerPage,
    onlyErrors,
    sortDirection,
    sortField,
    createdAt,
  ]);

  /**
   * Used to make sure that we are never on a page that can't exist
   */
  useEffect(() => {
    if (rowsPerPage > total && currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [currentPage, rowsPerPage, total]);

  /**
   * Used to update search results whenever the search options change
   */
  useEffect(() => {
    let loaded = true;
    async function getResults() {
      if (!token || loading || !loaded) {
        return;
      }

      setLoading(true);

      const search = searchConnections(api({ token }));
      const { count, total, results } = await search(searchOptions);
      setRows(resultsToRows(results));

      if (count > total) {
        setTotal(0);
      } else {
        setTotal(total);
      }
      setTimeout(() => setLoading(false), 300);
    }

    getResults();
    return () => {
      loaded = false;
    };
  }, [token, searchOptions]);

  /* ~~~ Utils ~~~ */

  function handleAppointmentIdChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const appointmentId = String(event.target.value);
    setAppointmentId(appointmentId);
  }

  function handleChangeCurrentPage(currentPage: number) {
    if (!loading) {
      setCurrentPage(currentPage);
    }
  }

  function handleChangeRowsPerPage(rowsPerPage: number) {
    if (!loading) {
      setRowsPerPage(rowsPerPage);
    }
  }

  function handleChangeSortDirection(sortDirection: SortDirection) {
    if (!loading) {
      setSortDirection(sortDirection);
    }
  }

  function handleChangeSortField(sortField: Column) {
    if (!loading) {
      setSortField(sortField);
      setSortDirection('desc');
    }
  }

  function handleRowClick(row: RowData[]) {
    const id = row[0];
    history.push(`/info/${id}`);
  }

  /* ~~~ Render ~~~ */

  return (
    <>
      <Grid container>
        <Grid item md={4}>
          <Box m={3}>
            <TextField
              value={createdAt}
              label="Created After"
              variant="filled"
              type="date"
              onChange={(e) => {
                setCreatedAt(e.target.value);
              }}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box m={3}>
            <TextField
              autoFocus
              label="Appointment ID"
              variant="filled"
              onChange={handleAppointmentIdChange}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box m={3}>
            <InputLabel>Show Only Errors</InputLabel>
            <Checkbox
              onChange={(event, val) => {
                setOnlyErrors(val);
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Table
        loading={loading}
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        onClick={handleRowClick}
        onChangeCurrentPage={handleChangeCurrentPage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        sortDirection={sortDirection}
        onChangeSortDirection={handleChangeSortDirection}
        sortField={sortField}
        onChangeSortField={handleChangeSortField}
      />
    </>
  );
}

function resultsToRows(results: Connection[]) {
  return results.map((data: any) => {
    const row = [];
    for (const column of columns) {
      const value = data[column.name];
      if (valueIsDate(column, value)) {
        row.push(new Date(value).toLocaleString());
      } else {
        row.push(value);
      }
    }
    return row as RowData[];
  });
}

function valueIsDate(column: Column, value: any): value is string {
  return column.name === 'created';
}

function setAppointmentFilter(
  options: SearchOptions,
  appointmentId: string,
  createdAt: string,
) {
  if (appointmentId) {
    if (!options.filter) {
      options.filter = {};
    }
    options.filter.appointment_id = likeFilter(appointmentId);
  }
  if (createdAt) {
    if (!options.filter) {
      options.filter = {};
    }
    options.filter.created_at = geFilter(createdAt);
  }

  return options;
}
