import LuxonUtils from '@date-io/luxon';
import { red } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getConfigValue } from '@timwoods/bf-config';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthProvider, { useAuth } from '../../auth';
import AppointmentInfo from '../../routes/appointment-info';
import Reports from '../../routes/home/reports';
import history from '../../utils/history';
import RippleLoader from '../utils/ripple-loader';
import './app.scss';
import { AppointmentAdmin } from './appointment-admin/AppointmentAdmin';
import Header from './header';
import Main from './main';
import { ScheduledAppointmentSearch } from './schedule-search/ScheduleAppointmentSearch';

function onRedirectCallback(appState: any) {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
}

function createTheme(options?: ThemeOptions): Theme {
  const newTheme = createMuiTheme(
    {
      palette: {
        primary: { main: '#FFFFFF' },
        secondary: { main: '#2196f3', light: '#99cefa' },
        error: { main: red.A400 },
        background: { default: '#FFFFFF' },
      },
      mixins: {
        toolbar: {
          fontSize: 'calc(1rem + 2vmin)',
          // minHeight: '86px',
        },
      },
    },
    options || {},
  );

  return responsiveFontSizes(newTheme);
}

export default function App() {
  const context = {
    theme: createTheme(),
    activeListItem: 'DefaultView',
    isReady: false,
  };

  return (
    <AuthProvider
      domain={getConfigValue('REACT_APP_AUTH_DOMAIN', 'telehealth.auth0.com')}
      client_id={getConfigValue(
        'REACT_APP_AUTH_CLIENT_ID',
        'CpgH3Bxidr0H8ajBX6IhQkP7MaSHK44B',
      )}
      redirect_uri={window.location.origin}
      audience={getConfigValue(
        'REACT_APP_AUTH_AUDIENCE',
        'https://be.lhitele.care',
      )}
      onRedirectCallback={onRedirectCallback}>
      <ThemeProvider theme={context.theme}>
        <CssBaseline />
        <IconContext.Provider
          value={{ color: context.theme.palette.secondary.main, size: '2em' }}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <AppContent />
          </MuiPickersUtilsProvider>
        </IconContext.Provider>
      </ThemeProvider>
    </AuthProvider>
  );
}

function AppContent() {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!loading) {
      if (!isAuthenticated) {
        loginWithRedirect();
      } else {
        setTimeout(() => {
          setReady(true);
        }, 500);
      }
    }
  }, [loading, isAuthenticated]);

  if (loading || !ready) {
    return (
      <div className="app-loader">
        <RippleLoader color="secondary" />
      </div>
    );
  }

  return (
    <Router>
      <Header />

      <main className="app-content">
        <Switch>
          <Route path="/schedule-search">
            <ScheduledAppointmentSearch />
          </Route>
          <Route path="/schedule-admin">
            <AppointmentAdmin />
          </Route>
          <Route path="/reports">
            <Reports />
          </Route>
          <Route path="/info/:id">
            <AppointmentInfo />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </main>
    </Router>
  );
}
