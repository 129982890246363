import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Modal,
  TextField,
} from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { WorkBook, utils } from 'xlsx';
import tzdata from 'tzdata';
import { DateTime } from 'luxon';
import { useStyles } from '@material-ui/pickers/views/Calendar/Day';
export const luxonValidTimezones = [
  ...new Set<string>(
    Object.keys(tzdata.zones).filter(
      (tz) => tz.includes('/') && DateTime.local().setZone(tz).isValid,
    ),
  ),
].sort((a, b) => (a < b ? -1 : 1));
type TimezoneConfigProps = {
  mapping: Record<string, string>;
  setMapping: (nm: Record<string, string>) => any;
  timezoneColumn: string;
  workbook: WorkBook;
  sheetName: string;
  open?: boolean;
  onClose?: () => any;
};

export const TimezoneConfig: FunctionComponent<TimezoneConfigProps> = ({
  mapping,
  setMapping,
  timezoneColumn,
  workbook,
  sheetName,
  open,
  onClose,
}) => {
  const timezones = useMemo(() => {
    const current = Object.keys(mapping);
    if (!workbook || !sheetName || !timezoneColumn) {
      return current;
    }
    const sheetData = utils.sheet_to_json(workbook.Sheets[sheetName]);
    const sheetTzs = sheetData
      .map((t: any) => t?.[timezoneColumn])
      .filter((t) => typeof t === 'string')
      .map((t) => t.toLowerCase())
      .reduce((pv, cv) => {
        if (pv.includes(cv) || current.includes(cv)) {
          return pv;
        }
        return [cv, ...pv];
      }, []);
    return [...current, ...sheetTzs];
  }, [workbook, sheetName, timezoneColumn]);
  const getMap = (name: string) => {
    return mapping[name] || '';
  };
  const setMap = (name: string, value: string) => {
    setMapping({ ...mapping, [name]: value });
  };
  return (
    <Modal open={open || false} onClose={onClose}>
      <Card style={{ margin: 1 }}>
        <CardHeader title="Timezone Configs"></CardHeader>
        <CardContent>
          {timezones.map((tz) => {
            return (
              <TextField
                key={tz}
                style={{
                  width: '100%',
                  margin: 8,
                }}
                select
                value={getMap(tz)}
                onChange={(s) => {
                  setMap(tz, s.target.value);
                }}
                variant="outlined"
                label={`Timezone ${tz}`}>
                {luxonValidTimezones.map((sn, i) => {
                  return (
                    <MenuItem key={sn} value={sn}>
                      {sn}
                    </MenuItem>
                  );
                })}
              </TextField>
            );
          })}
        </CardContent>
        <CardActions>
          <Button
            onClick={() => {
              onClose && onClose();
            }}>
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
