import { Box, TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Presets } from './Presets';

// ----------------------------------------------------------------------------

export type DateRangeValue = {
  start: DateTime;
  end: DateTime;
};

type DateRangeProps = {
  onChange: (dateRange: DateRangeValue) => void;
  preset: Presets;
};

// ----------------------------------------------------------------------------

export function DateRange({ onChange, preset }: DateRangeProps) {
  const [startDate, setStartDate] = useState(DateTime.local().toISODate());
  const [endDate, setEndDate] = useState(
    DateTime.local().plus({ day: 1 }).toISODate(),
  );

  useEffect(() => {
    switch (preset) {
      case 'FUTURE_APPT':
        setStartDate(DateTime.local().toISODate());
        setEndDate(DateTime.local().plus({ day: 1 }).toISODate());
        break;
      case 'LATE_LOGIN':
        setStartDate(DateTime.local().minus({ day: 1 }).toISODate());
        setEndDate(DateTime.local().toISODate());
        break;
    }
  }, [preset]);

  useEffect(() => {
    preset === 'CUSTOM' &&
      onChange &&
      onChange({
        start: DateTime.fromISO(startDate),
        end: DateTime.fromISO(endDate),
      });
  }, [startDate, endDate, onChange, preset]);

  return (
    <Box display="flex">
      {preset === 'CUSTOM' && (
        <>
          <TextField
            style={{ width: '100%', marginRight: 5 }}
            value={startDate}
            label="Scheduled After"
            variant="filled"
            type="date"
            disabled={preset !== 'CUSTOM'}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />

          <TextField
            style={{ width: '100%' }}
            value={endDate}
            label="Scheduled Before"
            variant="filled"
            type="date"
            disabled={preset !== 'CUSTOM'}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </>
      )}
    </Box>
  );
}
