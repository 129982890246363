import React, { useState } from 'react';
import { AppointmentMessage } from '../../types';
import Table, { Column, RowData } from '../table';

const columns: Column[] = [
  { name: 'sender', label: 'Sender', type: 'string' },
  { name: 'content', label: 'Message', type: 'string' },
  {
    name: 'date_created',
    label: 'Date',
    type: 'string',
    formatValue: (input) =>
      input ? new Date(String(input)).toLocaleString() : '',
  },
];

export type MessagesProps = {
  messages: AppointmentMessage[];
};

export default function Messages({ messages }: MessagesProps) {
  /* ~~~ State ~~~ */

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const rows = messagesToRows(messages);

  /* ~~~ Utils ~~~ */

  function handleChangeCurrentPage(currentPage: number) {
    setCurrentPage(currentPage);
  }

  function handleChangeRowsPerPage(rowsPerPage: number) {
    setCurrentPage(0);
    setRowsPerPage(rowsPerPage);
  }

  const pageRows = rows.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage,
  );

  while (pageRows.length < rowsPerPage) {
    pageRows.push(['', '', '']);
  }

  return (
    <Table
      columns={columns}
      rows={pageRows}
      total={rows.length}
      currentPage={currentPage}
      onChangeCurrentPage={handleChangeCurrentPage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      sortDirection="asc"
      onChangeSortDirection={() => {}}
      sortField={{ name: 'date_created', label: 'Date', type: 'string' }}
      onChangeSortField={() => {}}
    />
  );
}

function messagesToRows(messages: AppointmentMessage[]): RowData[][] {
  return messages.map((message) => {
    const row: RowData[] = [];
    for (const column of columns) {
      row.push((message as any)[column.name]);
    }
    return row;
  });
}
