import { Button, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import {
  CalendarTodayRounded,
  SettingsApplicationsRounded,
} from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { MouseEvent, useState } from 'react';
import { IoMdLogIn } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../auth';
import { getHostUrl } from '../../../utils';
import ProfilePicture from '../../profile-picture';
import './header.scss';
import Logo from './logo';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
  const styles = {
    toolbar: {
      justifyContent: 'space-between',
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    logo: {
      display: 'flex',
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      padding: 0,
      height: '100%',
      position: 'relative',
      maxWidth: 'unset',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 100,
      color: '#fff',
    },
  };
  (styles as any).appBarSpacer = theme.mixins.toolbar;
  return styles as any;
});

export default function Header() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth();
  const [open, setOpen] = useState(false);

  // function handleDrawerOpen() {
  //   setOpen(true);
  // }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleLogin() {
    loginWithRedirect();
  }

  function handleLogoutClick(event: MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    logout({ returnTo: getHostUrl() });
  }

  function handleLoginButtonClick(event: MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    handleLogin();
  }

  const classes: any = useStyles();

  return (
    <header className="app-header">
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}>
            <MenuIcon />
          </IconButton> */}
          <NavLink to="/" className={classes.logo}>
            <Logo className="header-home-icon" />
          </NavLink>
          <Typography style={{ alignItems: 'flex-start' }} variant="body1">
            <Button
              className="button-nav-link"
              component={NavLink}
              to="/reports"
              variant="text"
              startIcon={<AssignmentIcon />}>
              Reports
            </Button>

            <Button
              className="button-nav-link"
              component={NavLink}
              to="/schedule-search"
              variant="text"
              startIcon={<CalendarTodayRounded />}>
              Schedule Search
            </Button>

            <Button
              className="button-nav-link"
              component={NavLink}
              to="/schedule-admin"
              variant="text"
              startIcon={<SettingsApplicationsRounded />}>
              Schedule Admin
            </Button>
          </Typography>

          {!isAuthenticated && (
            <div
              className="login-button-container text-white nav-link pointer"
              onClick={handleLoginButtonClick}>
              <IoMdLogIn className="login-icon" size="2em" />
            </div>
          )}

          {isAuthenticated && (
            <div
              className="text-white nav-link pointer"
              onClick={handleLogoutClick}>
              <ProfilePicture className="header-profile-picture" />
            </div>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List />
      </Drawer>
    </header>
  );
}
