import { useEffect, useState } from 'react';
import { useAuth } from '../auth';

export default function useAuthToken() {
  const [token, setToken] = useState<string | undefined>(undefined);

  const { loading, isAuthenticated, getTokenSilently } = useAuth();

  useEffect(() => {
    async function getToken() {
      if (loading || !isAuthenticated) {
        return;
      }

      setToken(await getTokenSilently());
    }

    getToken();
  }, [loading, isAuthenticated, getTokenSilently]);

  return token;
}
