import React, { useState } from 'react';
import { AppointmentRating } from '../../types';
import Table, { Column, RowData } from '../table';

const columns: Column[] = [
  { name: 'value', label: 'Rating', type: 'string' },
  {
    name: 'date_created',
    label: 'Date',
    type: 'string',
    formatValue: (input) =>
      input ? new Date(String(input)).toLocaleString() : '',
  },
];

export type RatingsProps = {
  ratings: AppointmentRating[];
};

export default function Ratings({ ratings }: RatingsProps) {
  /* ~~~ State ~~~ */

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const rows = ratingsToRows(ratings);

  /* ~~~ Utils ~~~ */

  function handleChangeCurrentPage(currentPage: number) {
    setCurrentPage(currentPage);
  }

  function handleChangeRowsPerPage(rowsPerPage: number) {
    setCurrentPage(0);
    setRowsPerPage(rowsPerPage);
  }

  const pageRows = rows.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage,
  );

  while (pageRows.length < rowsPerPage) {
    pageRows.push(['', '']);
  }

  return (
    <Table
      columns={columns}
      rows={pageRows}
      total={rows.length}
      currentPage={currentPage}
      onChangeCurrentPage={handleChangeCurrentPage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      sortDirection="desc"
      onChangeSortDirection={() => {}}
      sortField={{ name: 'date_created', label: 'Date', type: 'string' }}
      onChangeSortField={() => {}}
    />
  );
}

function ratingsToRows(ratings: AppointmentRating[]): RowData[][] {
  return ratings.map((rating) => {
    const row: RowData[] = [];
    for (const column of columns) {
      row.push((rating as any)[column.name]);
    }
    return row;
  });
}
