import { Dialog, Grid } from '@material-ui/core';
import { utils, WorkBook } from 'xlsx';

import { DateTime } from 'luxon';
import Table, { Column } from '../../table';
import { useEffect, useState } from 'react';
import { SpreadsheetConfig } from './SpreadsheetConfig';
import { TimezoneConfig } from './TimezoneConfig';
import { FileUpload } from './FileUpload';
import { AppointmentUploadSummary } from './Summary';
import { useAuth } from '../../../auth';
import { useAuthToken } from '../../../hooks';
import api from '../../../services/api';

const getTimezone = (str: string, mapping: Record<string, string>) => {
  if (mapping[str.toLowerCase()]) {
    return mapping[str.toLowerCase()];
  }
  return 'America/Chicago';
};
const columns: Column[] = [
  { name: 'appointment_id', label: 'Appointment Id', type: 'string' },
  { name: 'scheduled_time', label: 'Date ( UTC )', type: 'string' },
  { name: 'local_time', label: 'Date ( Local )', type: 'string' },
  { name: 'timezone', label: 'Timezone', type: 'string' },
  { name: 'order_id', label: 'Order Id', type: 'string' },
  { name: 'provider_id', label: 'Provider Id', type: 'string' },
];

function ExcelDateToJSDate(
  serial: number,
  timezone: string,
  mapping: Record<string, string>,
) {
  // Deal with time zone
  var step = new Date().getTimezoneOffset() <= 0 ? 25567 + 2 : 25567 + 1;
  var utc_days = Math.floor(serial - step);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  return DateTime.local(
    date_info.getFullYear(),
    date_info.getMonth() + 1,
    date_info.getDate(),
    hours,
    minutes,
    seconds,
    { zone: getTimezone(timezone, mapping) },
  ).toJSDate();
}
export type AdminModals = false | 'spreadsheet' | 'timezone';
export const AppointmentAdmin = () => {
  const token = useAuthToken();

  const [workbook, setWorkbook] = useState<false | WorkBook>(false);
  const [sheetName, setSheetName] = useState<string>('');
  const [appointments, setAppointments] = useState<false | Array<string[]>>(
    false,
  );
  const uploadResults = async () => {
    if (!token || !appointments) {
      return;
    }
    const beApi = api({ token });
    const CHUNK_SIZE = 200;
    for (let i = 0; i < appointments.length; i += CHUNK_SIZE) {
      const chunk = appointments.slice(i, i + CHUNK_SIZE);
      // do whatever
      await beApi.post('appointments', {
        appointments: chunk.map((a) => {
          return {
            appointment_id: a[0],
            scheduled_start: a[1],
            local_timezone: a[3],
            order_id: a[4],
            provider_id: a[5],
          };
        }),
      });
    }
  };
  const [showAppointments, setShowAppointments] = useState(false);

  const [appointmentIdColumn, setAppointmentIdColumn] = useState('');
  const [orderIdColumn, setOrderIdColumn] = useState('');
  const [providerIdColumn, setProviderIdColumn] = useState('');
  const [dateColumn, setDateColumn] = useState('');
  const [timezoneColumn, setTimezoneColumn] = useState('');

  const [modal, setModal] = useState<AdminModals>(false);

  const [mapping, setMapping] = useState<Record<string, string>>({
    est: 'America/New_York',
    cst: 'America/Chicago',
    mst: 'America/Denver',
    pst: 'America/Los_Angeles',
    akst: 'America/Anchorage',
    hst: 'Pacific/Honolulu',
  });

  useEffect(() => {
    if (
      !workbook ||
      !workbook.SheetNames.includes(sheetName) ||
      !appointmentIdColumn ||
      !dateColumn ||
      !timezoneColumn
    ) {
      setAppointments(false);
      return;
    }
    const sheetData = utils.sheet_to_json(workbook.Sheets[sheetName]);

    setAppointments(
      sheetData.map((s: any) => {
        try {
          return [
            s[appointmentIdColumn].replace(/\*/g, ''),
            new Date(
              ExcelDateToJSDate(
                s[dateColumn],
                s[timezoneColumn],
                mapping,
              ).toUTCString(),
            ).toISOString(),
            new Date(
              ExcelDateToJSDate(s[dateColumn], s[timezoneColumn], mapping),
            ).toLocaleString('en-US', {
              timeZone: getTimezone(s[timezoneColumn], mapping),
            }),
            getTimezone(s[timezoneColumn], mapping),
            s[orderIdColumn].replace(/\*/g, ''),
            s[providerIdColumn],
          ];
        } catch (e: any) {
          return [
            s[appointmentIdColumn],
            `INVALID ${s[dateColumn]}`,
            s[timezoneColumn],
            s[orderIdColumn],
            s[providerIdColumn],
          ];
        }
      }),
    );
  }, [
    workbook,
    mapping,
    appointmentIdColumn,
    dateColumn,
    timezoneColumn,
    sheetName,
  ]);

  return (
    <>
      {workbook && (
        <SpreadsheetConfig
          {...{
            open: modal === 'spreadsheet',
            sheetName,
            setSheetName,
            workbook,
            appointmentIdColumn,
            setAppointmentIdColumn,
            dateColumn,
            setDateColumn,
            timezoneColumn,
            setTimezoneColumn,
            orderIdColumn,
            setOrderIdColumn,
            providerIdColumn,
            setProviderIdColumn,
            onClose: () => {
              setModal(false);
            },
          }}
        />
      )}
      {workbook && (
        <TimezoneConfig
          {...{
            open: modal === 'timezone',
            workbook,
            mapping,
            setMapping,
            timezoneColumn,
            sheetName,
            onClose: () => {
              setModal(false);
            },
          }}
        />
      )}
      <Grid container style={{ top: 50 }}>
        {appointments && (
          <Grid item sm={12} style={{ marginBottom: 8 }}>
            <AppointmentUploadSummary
              {...{
                appointments,
                mapping,
                workbook,
                sheetName,
                timezoneColumn,
                modal,
                setModal,
                showAppointments,
                setShowAppointments,
                onSubmit: () => {
                  uploadResults();
                },
              }}
            />
          </Grid>
        )}
        {workbook === false && (
          <Grid item sm={12}>
            <FileUpload setWorkbook={setWorkbook} />
          </Grid>
        )}

        {showAppointments && Array.isArray(appointments) && (
          <Grid item sm={12}>
            <Table
              loading={false}
              columns={columns}
              rows={appointments}
              total={appointments.length}
              currentPage={1}
              onChangeCurrentPage={() => {}}
              rowsPerPage={appointments.length}
              onChangeRowsPerPage={() => {}}
              sortDirection="desc"
              onChangeSortDirection={() => {}}
              sortField={{
                name: 'created_at',
                label: 'Created',
                type: 'string',
              }}
              onChangeSortField={() => {}}
              onClick={(r) => {
                console.log(r);
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
