import {
  TableFooter,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { grey } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import { getConfigValue } from '@timwoods/bf-config';
import React, { ChangeEvent, MouseEvent } from 'react';
import { IoMdSearch } from 'react-icons/io';
import { SortDirection } from '../../types';
import { isOdd } from '../../utils';
import { Column, RowData } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      overflowX: 'auto',
    },
    rowError: {
      color: `${theme.palette.warning.contrastText} !important`,
      backgroundColor: `${theme.palette.warning.main} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.warning.light} !important`,
      },
    },
    cellError: {
      color: `${theme.palette.error.contrastText}`,
      backgroundColor: `${theme.palette.error.main}`,
      '&:hover': {
        backgroundColor: `${theme.palette.error.light} !important`,
      },
    },
    table: {
      minWidth: 650,
    },
    header: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    selectedRow: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
      },
    },
    plainTableRow: {
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
      },
    },
    highlightedTableRow: {
      backgroundColor: grey[50],
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
      },
    },
    row: {},
    spacer: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    tableCell: {},
    tableCellContent: {
      minHeight: '20px',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    cursorAuto: {
      cursor: 'auto',
    },
  }),
);

export type TableProps = {
  columns: Column[];
  rows: RowData[][];
  total: number;
  loading?: boolean;

  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;

  rowsPerPage: number;
  onChangeRowsPerPage: (rowsPerPage: number) => void;

  sortDirection: SortDirection;
  onChangeSortDirection: (sortDirection: SortDirection) => void;

  sortField: Column;
  onChangeSortField: (sortField: Column) => void;

  onClick?: (rowData: RowData[]) => any;
};

export default function Table({
  columns,
  rows,
  total,
  loading = false,
  currentPage,
  onChangeCurrentPage,
  rowsPerPage,
  onChangeRowsPerPage,
  sortDirection,
  onChangeSortDirection,
  sortField,
  onChangeSortField,
  onClick,
}: TableProps) {
  async function handlePageChange(_: any, page: number) {
    await onChangeCurrentPage(page);
  }

  async function handleRowsPerPageChange(event: ChangeEvent<HTMLInputElement>) {
    await event.preventDefault();
    onChangeRowsPerPage(Number(event.target.value) || 25);
  }

  async function handleSortDirectionChange(sortDirection: SortDirection) {
    await onChangeSortDirection(sortDirection);
  }

  async function handleSortFieldChange(sortField: Column) {
    await onChangeSortField(sortField);
  }

  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      {loading || rows.length === 0 ? (
        <>
          <MuiTable className={classes.table}>
            <TableHead>
              <MuiTableRow>
                <TableHeaders
                  columns={columns}
                  sortDirection={sortDirection}
                  onChangeSortDirection={handleSortDirectionChange}
                  sortField={sortField}
                  onChangeSortField={handleSortFieldChange}
                />
              </MuiTableRow>
            </TableHead>
          </MuiTable>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            margin="1rem">
            {loading ? (
              <CircularProgress color="secondary" size={80} />
            ) : (
              <IoMdSearch size={80} />
            )}
          </Box>
          <MuiTable className={classes.table}>
            <TableFooter>
              <MuiTableRow>
                <TablePagination
                  colSpan={12}
                  count={total}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </MuiTableRow>
            </TableFooter>
          </MuiTable>
        </>
      ) : (
        <MuiTable className={classes.table}>
          <TableHead>
            <MuiTableRow>
              <TableHeaders
                columns={columns}
                sortDirection={sortDirection}
                onChangeSortDirection={handleSortDirectionChange}
                sortField={sortField}
                onChangeSortField={handleSortFieldChange}
              />
            </MuiTableRow>
          </TableHead>
          <TableBody>
            <TableRows onClick={onClick} columns={columns} rows={rows} />
          </TableBody>
          <TableFooter>
            <MuiTableRow>
              <TablePagination
                colSpan={12}
                count={total}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                onPageChange={handlePageChange}
              />
            </MuiTableRow>
          </TableFooter>
        </MuiTable>
      )}
    </Paper>
  );
}

type TableHeadersProps = {
  columns: Column[];
  sortDirection: SortDirection;
  sortField: Column;
  onChangeSortDirection: (direction: SortDirection) => void;
  onChangeSortField: (field: Column) => void;
};

function TableHeaders({
  sortDirection,
  onChangeSortDirection,
  sortField,
  onChangeSortField,
  ...props
}: TableHeadersProps) {
  const classes = useStyles();
  const columns = props.columns.filter((c) => !c.hidden);

  function clickHandler(field: Column) {
    return (event: MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      if (field.name === sortField.name) {
        if (sortDirection === 'asc') {
          onChangeSortDirection('desc');
        } else {
          onChangeSortDirection('asc');
        }
      } else {
        onChangeSortField(field);
      }
    };
  }

  return (
    <>
      {columns.map((field, index) => (
        <TableCell
          key={field.name + index}
          className={classes.header}
          align={index === 0 ? undefined : 'right'}>
          <TableSortLabel
            active={sortField.name === field.name}
            direction={sortDirection}
            onClick={clickHandler(field)}>
            {field.label}
            {sortField.name === field.name ? (
              <span className={classes.visuallyHidden}>
                {sortDirection === 'desc'
                  ? 'sorted descending'
                  : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
      <TableCell key="spacer" className={classes.spacer}></TableCell>
    </>
  );
}

type TableRowsProps = {
  columns: Column[];
  rows: RowData[][];
  onClick?: (rowData: RowData[]) => any;
};

function TableRows({ columns, rows, onClick }: TableRowsProps) {
  const classes = useStyles();
  return (
    <>
      {rows.map((row, index) => {
        const rowClassName = isOdd(index)
          ? classes.highlightedTableRow
          : classes.plainTableRow;
        return (
          <TableRow
            onClick={() => {
              if (onClick) {
                onClick(row);
              }
            }}
            key={index}
            className={`${classes.row} ${rowClassName} ${
              onClick ? classes.cursorPointer : classes.cursorAuto
            }`}
            columns={columns}
            dataFields={row}
          />
        );
      })}
    </>
  );
}

type TableRowProps = {
  className: string;
  columns: Column[];
  dataFields: RowData[];
  onClick: () => any;
};

const FormatCell = (
  column: Column,
  data: RowData,
  row: Record<string, RowData>,
): RowData | JSX.Element => {
  if (column.formatValue) {
    return column.formatValue(data, row);
  }
  return data;
};
const ValidateCell = (
  column: Column,
  data: RowData,
  row: Record<string, RowData>,
): boolean => {
  const regexTest = getConfigValue(
    `REACT_APP_ERROR_REGEXP_${column.name.toUpperCase()}`,
  );
  if (regexTest !== '') {
    return new RegExp(regexTest).test(String(data));
  }
  if (column.validate) {
    return column.validate(data, row);
  }
  return true;
};

function TableRow({ className, columns, dataFields, onClick }: TableRowProps) {
  const classes = useStyles();

  const visibleColumns = columns.filter((c) => !c.hidden);

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  const cellValues: Array<string | JSX.Element> = [];
  const columnCount = visibleColumns.length;

  const row: Record<string, RowData> = columns
    .map((value, index) => {
      return { [value.name]: dataFields[index] };
    })
    .reduce((cv, pv) => {
      return { ...cv, ...pv };
    }, {});

  const valid: boolean[] = columns.map((column, index) => {
    return ValidateCell(column, dataFields[index], row);
  });
  for (let i = 0; i < columnCount; i++) {
    const column = visibleColumns[i];
    const dataField = FormatCell(column, dataFields[i], row);
    switch (typeof dataField) {
      case 'undefined':
      case 'object':
        if (/react/.test(String(dataField?.type?.$$typeof))) {
          cellValues.push(dataField);
        } else {
          cellValues.push('N/A');
        }
        break;
      case 'number':
      case 'boolean':
        cellValues.push(String(dataField));
        break;
      default:
        cellValues.push(dataField);
        break;
    }
  }

  const [firstField, ...otherFields] = cellValues;
  const cell1: { className?: string } = {};
  if (!valid[0]) {
    cell1.className = classes.cellError;
  }
  const rowValid = valid.reduce((cv, pv) => cv && pv, true);
  const rowClases: string[] = [className];
  if (!rowValid) {
    rowClases.push(classes.rowError);
  }
  return (
    <MuiTableRow
      className={rowClases.reverse().join(' ')}
      onClick={handleClick}>
      <TableCell
        className={classes.tableCell}
        {...cell1}
        component="th"
        scope="row">
        <div className={classes.tableCellContent}>{firstField}</div>
      </TableCell>
      {otherFields.map((value, index) => {
        const c: { className?: string } = {};
        if (!valid[index + 1]) {
          c.className = classes.cellError;
        }

        return (
          <TableCell
            className={classes.tableCell}
            {...c}
            key={index}
            align="right">
            <div className={classes.tableCellContent}>{value}</div>
          </TableCell>
        );
      })}
      <TableCell></TableCell>
    </MuiTableRow>
  );
}
